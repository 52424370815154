.hg-theme-default {
	background-color: transparent;
	font-weight: 300;
	font-size: 1.5rem;
	padding: 0;

	.hg-row {
		justify-content: center;
	}

	.hg-button {
		flex-grow: 0;
		background-color: #434343;
		border: 1px solid #8b8b8b;
		color: #efefef;

		&:active,
		&:focus, {
			background-color: #333333;
		}

		&.hg-activeButton {
			background-color: #333333;
		}

		&.hg-standardBtn {
			flex-grow: 0;
			width: 64px;
			height: 56px;
			color: #efefef;
		}
	}

	.hg-button-backspace {
		width: 64px;
		height: 56px;
		font-size: 2rem;
		color: #efefef;
	}

	.hg-button-space {
		width: 300px;
		height: 56px;
		font-size: 2rem;
		color: #efefef;
	}

	.hg-button-enter {
		height: 56px;
		width: 160px;
		font-weight: 700;
		color: #efefef;
	}
}
